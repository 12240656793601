<template>
  <slide-over-right :id="sorId" :full-width="true">
    <loading :active.sync="isLoading"></loading>
    <div class="flex flex-wrap">
      <div class="w-1/2 md-max:w-full">
        <div class="flex items-center pl-8 mt-6 md-max:pb-6">
          <div
            class="items-center justify-center cursor-pointer back-button"
            @click="goBack"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <h2 class="ml-3 font-semibold text-gray-900 text-22px">
            {{ titleText }}
          </h2>
        </div>

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(confirmSubmit)" class="px-6">
            <div class="grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2">
              <text-input
                rules="required"
                :name="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.parkingName'
                  )
                "
                :label="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.parkingName'
                  )
                "
                v-model="form.name"
              />

              <ValidationProvider
                :name="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.assignedServiceArea'
                  )
                "
                rules="required"
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                  :text="
                    $t(
                      'components.parkingAreaManagement.addEdit.steps.details.title.assignedServiceArea'
                    )
                  "
                />
                <t-rich-select
                  name="options"
                  :options="service_areas"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="false"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.geofence"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>

            <div class="flex items-end gap-2 mt-1">
              <div class="w-full">
                <PlacesAutocomplete
                  :label="
                    $t(
                      'components.parkingAreaManagement.addEdit.steps.details.title.searchForLocation'
                    )
                  "
                  :disabled="!!!form.geofence"
                  :placeholder="
                    !!form.geofence === false
                      ? $t(
                          'components.parkingAreaManagement.addEdit.steps.details.placeHolder.serviceAreaAtFirst'
                        )
                      : $t(
                          'components.parkingAreaManagement.addEdit.steps.details.placeHolder.enterAddress'
                        )
                  "
                  @update:place="onUpdatingPlace"
                  @clear:place="() => (place = null)"
                />
              </div>
              <UploadGeoFile v-on:input="addKmlLayer" />
            </div>
            <div class="grid grid-cols-1 gap-2 mt-4">
              <AppInput
                type="richselect"
                :name="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.areaType'
                  )
                "
                :label="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.areaType'
                  )
                "
                :placeholder="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.placeHolder.selectOne'
                  )
                "
                :options="areaTypeOptions"
                hide-search-box
                v-model="form.operation_mode"
              />
            </div>
            <div class="grid grid-cols-1 gap-2">
              <AppInput
                type="richselect"
                :name="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.limitedParkingCapacity'
                  )
                "
                :label="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.limitedParkingCapacity'
                  )
                "
                :placeholder="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.placeHolder.selectOne'
                  )
                "
                :options="[
                  { value: true, text: 'Yes' },
                  { value: false, text: 'No' },
                ]"
                hide-search-box
                v-model="form.has_limited_parking_capacity"
              />
              <text-input
                v-if="form.has_limited_parking_capacity"
                rules="required"
                type="number"
                :name="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.maximumVehicle'
                  )
                "
                :label="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.maximumVehicle'
                  )
                "
                v-model="form.max_vehicle"
              />
            </div>

            <div class="grid grid-cols-1 gap-2 ">
              <ValidationProvider
                :name="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.address'
                  )
                "
                rules=""
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                  :text="
                    $t(
                      'components.parkingAreaManagement.addEdit.steps.details.title.address'
                    )
                  "
                />
                <t-textarea
                  rules="required"
                  v-model="form.address"
                ></t-textarea>
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
            <div class="grid grid-cols-1 gap-2">
              <ValidationProvider
                :name="
                  $t(
                    'components.parkingAreaManagement.addEdit.steps.details.title.parkingNote'
                  )
                "
                rules=""
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                  :text="
                    $t(
                      'components.parkingAreaManagement.addEdit.steps.details.title.parkingNote'
                    )
                  "
                />
                <t-textarea
                  rules="required"
                  v-model="form.description"
                ></t-textarea>
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>

            <button type="submit" ref="submitButton" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>

        <div class="flex items-center justify-end pr-2 mt-6">
          <anchor-button variant="secondary" @click="goBack">
            {{ $t('components.stepNavigation.cancel') }}
          </anchor-button>
          <t-button type="submit" @click="submit()" class="ml-3">{{
            $t('components.stepNavigation.save')
          }}</t-button>
        </div>
      </div>

      <div class="w-1/2 md-max:w-full md-max:pt-6">
        <gmap-map
          ref="map"
          :zoom="zoom"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100vh;"
        >
          <gmap-polygon
            :paths="parseToOverlay(form.coords)"
            :editable="true"
            :options="drawingOptions"
            @paths_changed="updateEdited($event)"
          >
          </gmap-polygon>
        </gmap-map>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { ParkingAreaConfig } from '@/config/ParkingAreaConfig'
import { gmapApi } from 'vue2-google-maps'
import { UploadGeoFile } from '@/components/form'
import { useEndpoints } from '@/composables'
export default {
  name: 'AddEditParkingArea',
  components: {
    UploadGeoFile,
    SlideOverRight: () => import('@/components/modals/SlideOverRight'),
    AnchorButton: () => import('@/components/form/AnchorButton'),
    TextInput: () => import('@/components/form/TextInput'),
    InputLabel: () => import('@/components/form/InputLabel'),
    PlacesAutocomplete: () => import('@/components/map/PlacesAutocomplete'),
  },
  data() {
    return {
      isLoading: false,
      addTitle: this.$t(
        'components.parkingAreaManagement.addEdit.headline.add'
      ),
      all_parking_areas: [],
      parking_areas: [],
      service_areas: [],
      restricted_areas: [],
      slow_speed_areas: [],
      rent_station_areas: [],
      sunpod_station_areas: [],
      reward_zones: [],
      areaTypeOptions: [],
      item: {},
      form: {
        name: null,
        geofence: null,
        operation_mode: 'PARK_AND_DROP',
        has_limited_parking_capacity: false,
        max_vehicle: null,
        operator_visibility: false,
        end_ride_picture: false,
        coords: null,
        address: '',
        description: '',
      },
      center: {
        lat: 10,
        lng: 10,
      },
      errors: [],
      sorId: ParkingAreaConfig.events.sorId,
      scrollwheel: true,
      zoom: 12,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      markers: [{}],
      edited: null,
      map: null,
      drawingManager: null,
      mapDrawingMode: 'Polygonal',
      parsedPolygon: null,
      parsedServicePolygon: null,
      drawingOptions: {
        fillColor: 'rgba(30, 152, 254, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#00acff',
        clickable: true,
        draggable: true,
        editable: true, // TODO: make this work with wrapper func
        zIndex: 8,
      },
      serviceDrawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      restrictedDrawingOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#ff0000',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 2,
      },
      slowSpeedDrawingOptions: {
        fillColor: 'rgba(156, 163, 175, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#9CA3AF',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 3,
      },
      parkingDrawingOptions: {
        fillColor: 'rgba(30, 152, 254, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#00acff',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 4,
      },
      rentStationDrawingOptions: {
        fillColor: 'rgba(88, 28, 135, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#581C87',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 5,
      },
      sunpodStationDrawingOptions: {
        fillColor: 'rgba(190, 24, 93, 0.13',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#BE185D',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 6,
      },
      rewardZoneDrawingOptions: {
        fillColor: 'rgba(204, 204, 20, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#CCCC00',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 7,
      },
      service_bounds: null,
      images: [],
      show_images: [],
      // places autocomplete
      theZoom: null,
      location_name: '',
      current_coords: '',
    }
  },
  computed: {
    google: gmapApi,
    drawingType: function() {
      return [
        {
          id: 'CIRCLE',
          name: 'Circle',
        },
        {
          id: 'POLYGON',
          name: 'Polygon',
        },
      ]
    },
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    titleText: function() {
      return this.isEditing
        ? this.$t('components.parkingAreaManagement.addEdit.headline.update')
        : this.addTitle
    },
    actionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    getServiceArea: function() {
      return this.form.geofence || undefined
    },
  },
  watch: {
    // parsedPolygon() {
    //   if (this.parsedPolygon) {
    //     this.bindPolygonClickListener(this.parsedPolygon)
    //   }
    // },
    getServiceArea(updatedID) {
      console.log('updateGeof = ', this.service_areas)
      this.service_areas.forEach((item) => {
        if (item.id == updatedID) {
          if (this.isEditing) {
            this.current_coords = item.coords
          } else {
            if (!this.$refs.map) return
            this.$refs.map.$mapPromise.then((map) => {
              this.map = map
              this.removeOverlayMap()
              const overlay = this.parseToOverlay(item.coords)
              this.getServiceBounds(overlay)
              this.setServiceOverlayOnMap(overlay)
              this.setMapCenterToDrawingCenter(overlay, false)
              // save the current coords for places autocomplete
              this.current_coords = item.coords
              this.drawOtherZonesOnMap()
            })
          }
        } else {
          this.removeOverlayMap()
        }
      })
    },
  },
  async created() {
    await this.loadServiceDropdown()
    await this.$http
      .get(this.$config.parkingArea.api.operationModeChoices())
      .then((res) => {
        this.areaTypeOptions = res.data
      })
  },
  mounted() {
    EventBus.$on(ParkingAreaConfig.events.editingData, (item) => {
      this.show_images = []
      this.images = []
      this.theZoom = null
      if (item.id && item.id != undefined) {
        this.item = item
        this.form = {
          name: item.name,
          geofence: item.geofence.id,
          operation_mode: item.operation_mode,
          has_limited_parking_capacity: item.has_limited_parking_capacity,
          max_vehicle: item.max_vehicle,
          operator_visibility: item.operator_visibility,
          end_ride_picture: item.end_ride_picture,
          coords: item.coords,
          address: item.address,
          description: item.description,
        }
        this.parking_areas = this.all_parking_areas.filter(
          (area) => area.id !== item.id
        )
      } else {
        this.form = {}
        this.item = {}
        this.parking_areas = this.all_parking_areas
        setTimeout(() => {
          if (!this.$refs.map) return
          this.$refs.map.$mapPromise.then((map) => {
            console.log('Hitting promise with', map)
            this.map = map
            this.initDrawingMode()
          })
        }, 400)
      }
      console.log('data = ', item, this.editingData)
    })
    this.$log.debug('EditData:', this.form)
    this.getCurrentLocation()

    console.log('Map Ref', this.$refs.map)
  },
  updated: function() {
    var self = this
    this.$nextTick(function() {
      console.log('updated')
      // Code that will run only after the
      // entire view has been re-rendered
      if (!self.$refs.map) return
      // the zoom is set only by places auto complete,
      // allow centeting & zooming the map
      //  if user is searching for a place
      if (this.theZoom) return

      this.$refs.map.$mapPromise.then((map) => {
        console.log('Hitting promise with', map)
        this.map = map
        if (this.isEditing) {
          console.warn({ isE: this.isEditing })
          //set service area view
          if (this.item.geofence.coords) {
            this.removeOverlayMap()

            const overlay = this.parseToOverlay(this.item.geofence.coords)
            this.getServiceBounds(overlay)
            this.setServiceOverlayOnMap(overlay)
            this.drawOtherZonesOnMap()
          }
          //set parking draw
          const overlay = this.parseToOverlay(this.form.coords)
          // this.setOverlayOnMap(overlay)
          this.setMapCenterToDrawingCenter(overlay, false)
        }
        this.initDrawingMode()
      })
    })
  },
  methods: {
    addKmlLayer(kmlCode) {
      let latLngPairs = []
      const kmlParser = new DOMParser()
      const xmlDocument = kmlParser.parseFromString(kmlCode, 'text/xml')

      const placemarks = xmlDocument.getElementsByTagName('Placemark')
      for (const placemark of placemarks) {
        const polygons = placemark.getElementsByTagName('Polygon')
        console.log('coordinatesElement', polygons)
        for (const polygon of polygons) {
          const outerBoundaryIs = polygon.getElementsByTagName(
            'outerBoundaryIs'
          )[0]
          const linearRing = outerBoundaryIs.getElementsByTagName(
            'LinearRing'
          )[0]
          const coordinatesElement = linearRing.getElementsByTagName(
            'coordinates'
          )[0]

          const coordinates = coordinatesElement.textContent.trim()
          const coordinateArray = coordinates.split(/[\s,]+/)

          // Create pairs of latitude and longitude

          for (let i = 0; i < coordinateArray.length; i += 3) {
            const lat = parseFloat(coordinateArray[i + 1])
            const lng = parseFloat(coordinateArray[i])
            latLngPairs.push({ lat, lng })
          }

          console.log('addKmlLayer-i', latLngPairs)
        }
      }

      this.form.coords = this.getCoordStrFromCoordArray(latLngPairs, false)
      console.log('addKmlLayer', this.form.coords)
      this.drawKMLArea(this.form.coords)
    },
    drawKMLArea(coords) {
      const overlay = this.parseToOverlay(coords)
      this.setOverlayOnMap(overlay)
      this.setMapCenterToDrawingCenter(overlay, false)
    },
    updateEdited(mvcArray) {
      let sPaths = ''
      for (let i = 0; i < mvcArray.getLength(); i++) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j)
          if (i === 0 && j === 0) {
            sPaths = sPaths.concat(`{${point.lat()},${point.lng()}}`)
          } else {
            sPaths = sPaths.concat(',', `{${point.lat()},${point.lng()}}`)
          }
        }
      }
      this.form.coords = sPaths
    },

    goBack() {
      this.theZoom = null
      dispatchEvent(new Event(ParkingAreaConfig.events.sorToggle))
    },
    initDrawingMode() {
      if (this.drawingManager) {
        this.drawingManager.setMap(null)
      }
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: this.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            this.google.maps.drawing.OverlayType.POLYGON,
            this.google.maps.drawing.OverlayType.CIRCLE,
          ],
        },
        // markerOptions: {
        //   icon:
        //     "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
        // },
        circleOptions: this.drawingOptions,
        polygonOptions: this.drawingOptions,
      })
      console.log('Manager ', this.drawingManager)
      this.drawingManager.setMap(this.map)
      this.google.maps.event.addListener(
        this.drawingManager,
        'overlaycomplete',
        this.overlayCompleted
      )
      // this.google.maps.event.addListener(
      //   this.drawingManager,
      //   "polygoncomplete",
      //   function(polygon) {
      //   }
      // );
      this.google.maps.event.addListener(
        this.drawingManager,
        'circlecomplete',
        this.circleCompleted
      )
    },
    getServiceBounds(overlay) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of overlay) {
        bounds.extend(path)
      }
      this.service_bounds = bounds
    },
    setServiceOverlayOnMap(payload) {
      this.parsedServicePolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.serviceDrawingOptions,
      })
      this.parsedServicePolygon.setMap(this.map)
      console.log('po = ', this.parsedServicePolygon)
    },
    removeOverlayMap() {
      if (this.parsedServicePolygon) this.parsedServicePolygon.setMap(null)

      if (this.parsedPolygon) this.parsedPolygon.setMap(null)

      if (this.otherParsedPolygons) {
        for (let i = 0; i < this.otherParsedPolygons.length; ++i) {
          this.otherParsedPolygons[i].setMap(null)
        }
      }
    },
    checkContains(event) {
      var exist_flag = true

      var bounds = new this.google.maps.LatLngBounds()
      for (const path of event) {
        bounds.extend(path)
      }

      this.form.center_coords =
        bounds.getCenter().lat() + ',' + bounds.getCenter().lng()

      for (const path of event) {
        if (!this.service_bounds.contains(path)) {
          exist_flag = false
          break
        }
      }
      console.log(exist_flag)
      return exist_flag
    },
    circleCompleted(event) {
      if (event.type == this.google.maps.drawing.OverlayType.Circle) {
        if (this.parsedPolygon) this.parsedPolygon.setMap(null)
        this.mapDrawingMode = 'Circular'
        this.form.draw_type = 'C'
        const coordStr = this.getCircleCoords(event)
        this.setDrawnCoords(coordStr, event)
      }
    },
    overlayCompleted(event) {
      if (event.type == this.google.maps.drawing.OverlayType.POLYGON) {
        if (this.parsedPolygon) this.parsedPolygon.setMap(null)
        // this.bindPolygonClickListener(event.overlay);
        this.mapDrawingMode = 'Polygonal'
        this.form.draw_type = 'P'
        const coordStr = this.getPolygonCoords(event)
        this.setDrawnCoords(coordStr, event)
      }
    },
    bindPolygonClickListener(overlay) {
      console.log(overlay)
    },
    getCircleCoords(circle) {
      var numPts = 20
      var path = []
      for (var k = 0; k < numPts; k++) {
        path.push(
          this.google.maps.geometry.spherical.computeOffset(
            circle.getCenter(),
            circle.getRadius(),
            (k * 360) / numPts
          )
        )
      }
      console.log('Circle Path', path)
      var dumpStr = ''
      for (var i = 0; i < path.length; i++) {
        console.log(path[i].lat())
        dumpStr += `{${path[i].lat()},${path[i].lng()}},`
      }
      console.log('Circle draw completed', dumpStr)
      return dumpStr
    },
    getCoordStrFromCoordArray(coordinatesArray, isNativePolygon = true) {
      var dumpStr = ''
      for (var item of coordinatesArray) {
        console.log(item)
        dumpStr += `{${isNativePolygon ? item.lat() : item.lat},${
          isNativePolygon ? item.lng() : item.lng
        }},`
      }
      console.log('Poly draw completed', dumpStr)
      return dumpStr
    },
    getPolygonCoords(polygon) {
      var coordinatesArray = polygon.overlay.getPath().getArray()
      return this.getCoordStrFromCoordArray(coordinatesArray, true)
    },
    setDrawnCoords(coords, sender) {
      // this is a tricky code--added for re-rendering
      if (
        this.addTitle ===
        `${this.$t('components.parkingAreaManagement.addEdit.headline.add')} `
      ) {
        this.addTitle = `${this.$t(
          'components.parkingAreaManagement.addEdit.headline.add'
        )}`
      } else {
        this.addTitle = `${this.$t(
          'components.parkingAreaManagement.addEdit.headline.add'
        )} `
      }
      this.form.coords = coords
      console.log(sender)
      if (this.parsedPolygon) {
        this.parsedPolygon.setMap(null)
      }
      // this.drawingManager.setMap(null);
      if (this.parsedPolygon !== sender) {
        sender.overlay ? sender.overlay.setMap(null) : sender.setMap(null)
      }
      // this.initDrawingMode();
      var that = this
      this.$nextTick(function() {
        const overlay = that.parseToOverlay(coords)
        if (this.service_bounds != null && this.checkContains(overlay)) {
          // that.setOverlayOnMap(overlay)

          that.setMapCenterToDrawingCenter(overlay, true)
        } else {
          // alert('Please draw Parking Area inside Service Area.')
        }
      })
    },
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!Array.isArray(coordsArr) || !coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    setOverlayOnMap(payload) {
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.drawingOptions,
      })

      this.parsedPolygon.setMap(this.map)
      console.log(payload)
      console.log('SET ON MAP')
    },
    // updatePolygonBounds(index, path, payload) {
    //   console.log('HIT UPDATE POLY...', payload[index])
    //   if (!this.parsedPolygon) return
    //   payload[index] = {
    //     lat: parseFloat(path.lat()),
    //     lng: parseFloat(path.lng()),
    //   }
    //   console.log('HIT UPDATED POLY...', {
    //     lat: parseFloat(path.lat()),
    //     lng: parseFloat(path.lng()),
    //   })
    //   // payload[index] = path
    //   const coordStr = this.getCoordStrFromCoordArray(payload, false)
    //   console.log('STORED COORDS', this.form.coords)
    //   console.log('DIFFED COORDS', coordStr)
    //   this.form.coords = coordStr
    //   console.log('STORED UPD', this.form.coords)
    //   // this.setDrawnCoords(coordStr, this.parsedPolygon)
    // },
    setMapCenterToDrawingCenter(
      paths,
      panToCenter = true,
      latlng = null,
      zoom = null
    ) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      console.log('NEW CENTER ', newCenter, panToCenter, latlng, zoom)

      if (panToCenter) {
        this.map.panTo(newCenter)
      }

      if (latlng) {
        this.center.lat = latlng.lat
        this.center.lng = latlng.lng
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }

      if (zoom) {
        this.zoom = zoom
      } else {
        //reset map zoom based on new poly (can't see whole poly on render if the poly is bigger then the current set zoom)
        this.map.fitBounds(bounds)
      }
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position);
        this.center.lat = position.coords.latitude
        this.center.lng = position.coords.longitude
      })
    },
    checkValidPolygon(coordStr) {
      if (!coordStr) return false

      const coords = coordStr.split(',')
      return Array.isArray(coords) && coords.length > 2
    },
    submit: async function() {
      console.log('COORDS', this.form.coords)
      if (!this.checkValidPolygon(this.form.coords)) {
        alert(
          'Please draw a valid polygon containing more than two coordinates'
        )
        return
      }
      if (typeof this.form.operator_visibility === 'undefined') {
        this.form.operator_visibility = false
      }
      if (typeof this.form.end_ride_picture === 'undefined') {
        this.form.end_ride_picture = false
      }
      console.log('form===', this.form)
      if (this.form.coords === undefined || this.form.coords === null) {
        this.isLoading = false
        alert('Please draw a valid Parking Area (Polygon/Circle)')
        return
      } else {
        this.isLoading = false
      }
      this.$refs.submitButton.click()
    },
    confirmSubmit: async function() {
      this.isLoading = true
      console.log('form = ', this.images)
      if (this.form.description == undefined) {
        this.form.description = ''
      }
      if (this.form.address == undefined) {
        this.form.address = ''
      }

      delete this.form.draw_type

      if (this.form.max_vehicle)
        this.form.max_vehicle = Number(this.form.max_vehicle)

      if (!this.form.description) {
        await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.center.lat},${this.center.lng}&key=AIzaSyC6mg5slEqKmJxXDo6kLgYtHXg_S0AQJ4A`
        )
          .then((res) => res.json())
          .then((res) => {
            this.saveData(res.results[0].formatted_address)
          })
          .catch(() => this.saveData(`${this.center.lat},${this.center.lng}`))
      } else {
        await this.saveData()
      }
    },
    async saveData(description = '') {
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? ParkingAreaConfig.api.update(this.item.id)
        : ParkingAreaConfig.api.create

      let data = new xMan().toFormData()

      data.append('coords', this.form.coords)
      data.append('geofence', this.form.geofence)
      data.append('name', this.form.name)
      data.append('address', this.form.address ? this.form.address : '')
      data.append(
        'has_limited_parking_capacity',
        this.form.has_limited_parking_capacity
      )
      if (this.form.max_vehicle) {
        data.append('max_vehicle', this.form.max_vehicle)
      }
      data.append(
        'description',
        this.form.description ? this.form.description : `${description}`
      )

      data.append('operation_mode', this.form.operation_mode)
      data.append('operator_visibility', this.form.operator_visibility)
      data.append('end_ride_picture', this.form.end_ride_picture)
      data.append('center_coords', this.form.center_coords)
      this.images.forEach((item) => {
        data.append('parking_images', item)
      })

      let message = this.isEditing
        ? 'Parking Area updated successfully'
        : 'Parking Area added successfully'

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)
        await this.loadServiceDropdown()
        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(ParkingAreaConfig.events.sorToggle))
        dispatchEvent(new Event(ParkingAreaConfig.events.refresh))
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (error) {
        this.isLoading = false
        const errResponse = error.response ? error.response.data : null
        message = 'Invalid parking area'

        if (errResponse) {
          if (errResponse.length) {
            message = errResponse[0]
          } else {
            message = errResponse.detail
          }
        }

        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: message,
          },
          5000
        )
      }
    },
    async onUpdatingPlace(data) {
      var lat = data.geometry.location.lat().toString()
      var lng = data.geometry.location.lng().toString()

      console.log('lat = ', lat, lng)

      const center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }

      const overlay = this.parseToOverlay(this.current_coords)
      console.warn(overlay)
      this.getServiceBounds(overlay)
      this.setServiceOverlayOnMap(overlay)
      // varying zoom
      this.theZoom = 18
      if (this.zoom === this.theZoom) {
        this.theZoom = 17
      } else {
        this.theZoom = 18
      }
      this.setMapCenterToDrawingCenter(overlay, false, center, this.theZoom)
      // this.initDrawingMode()

      // this.zoom = 50
    },
    addNewImage(file) {
      console.log('file = ', file)
      this.images.push(file)
    },
    addShowImage(pre_file) {
      console.log('file = ', pre_file)
      var obj = {
        preImage: true,
        image: pre_file,
      }
      this.show_images.push(obj)
    },
    async loadServiceDropdown() {
      // console.log('service = ', this.$config.dorpdown.api.serviceAreas)
      const areasDataAPICalls = [
        this.$http.get(`${this.$config.serviceArea.api.index}?export=true`),
        this.$http.get(`${this.$config.slowSpeedArea.api.index}?export=true`),
        this.$http.get(`${this.$config.restrictedArea.api.index}?export=true`),
        this.$http.get(`${this.$config.parkingArea.api.index}?export=true`),
        this.$http.get(
          `${useEndpoints.geoFence.rentStationArea.index()}?export=true`
        ),
        this.$http.get(
          `${this.$config.sunpodStationArea.api.index}?export=true`
        ),
        this.$http.get(`${this.$config.rewardZone.api.index}?export=true`),
      ]
      try {
        const responses = await Promise.allSettled(areasDataAPICalls)
        console.log('test-responses', responses)
        responses.map((response) => {
          if (response.status === 'fulfilled') {
            if (response.value.config.url.includes('service-areas')) {
              this.service_areas = response.value.data.data
            }
            if (response.value.config.url.includes('slow-zone-areas')) {
              this.slow_speed_areas = response.value.data.data
            }
            if (response.value.config.url.includes('restricted-areas')) {
              this.restricted_areas = response.value.data.data
            }
            if (response.value.config.url.includes('parking-areas')) {
              this.all_parking_areas = response.value.data.data
              this.parking_areas = response.value.data.data
            }
            if (response.value.config.url.includes('rent-areas')) {
              this.rent_station_areas = response.value.data.data
            }
            if (response.value.config.url.includes('sunpod-areas')) {
              this.sunpod_station_areas = response.value.data.data
            }
            if (response.value.config.url.includes('reward-zones')) {
              this.reward_zones = response.value.data.data
            }
          }
          if (response.status === 'rejected') {
            let message = ''
            if (response.reason.config.url.includes('service-areas')) {
              message = `Service Area: ${response.reason.response.data.detail}`
            }
            if (response.reason.config.url.includes('slow-zone-areas')) {
              message = `Slow Zone Area: ${response.reason.response.data.detail}`
            }
            if (response.reason.config.url.includes('restricted-areas')) {
              message = `Restricted Area: ${response.reason.response.data.detail}`
            }
            if (response.reason.config.url.includes('parking-areas')) {
              message = `Parking Area: ${response.reason.response.data.detail}`
            }
            if (response.reason.config.url.includes('rent-areas')) {
              message = `Rent Area: ${response.reason.response.data.detail}`
            }
            if (response.reason.config.url.includes('sunpod-areas')) {
              message = `Sunpod Area: ${response.reason.response.data.detail}`
            }
            if (response.reason.config.url.includes('reward-zones')) {
              message = `Reward Area: ${response.reason.response.data.detail}`
            }
            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: `Error [${response.reason.response.status}]`,
                text: message,
              },
              5000
            )
          }
        })
      } catch (err) {
        console.log('Error loading areas data', err)
      }
    },
    drawOtherZonesOnMap() {
      const otherAreaBounds = []
      const otherParsedPolygons = []
      this.restricted_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.restrictedDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })
      this.sunpod_station_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.sunpodStationDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.reward_zones.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.rewardZoneDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })
      this.slow_speed_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.slowSpeedDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })
      this.rent_station_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.rentStationDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.parking_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.parkingDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.otherAreaBounds = otherAreaBounds
      this.otherParsedPolygons = otherParsedPolygons
    },
  },
}
</script>

<style lang="scss">
.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  padding: 4px 0; // 15px 0?

  & .left {
    width: 65%;
    padding-right: 0.5rem;
    font-size: 0.875rem;
  }

  & .right {
    width: 35%;
    padding-left: 0.5rem;
  }
}
.toggle-button-active {
  border: 1px solid #1bca09;
  width: 100%;
  height: 140px;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-button {
  width: 100%;
  height: 140px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.toggle-description {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  @apply text-gray-600;
}
.places-autocomplete {
  @apply relative block w-full px-4 py-2 leading-normal border border-gray-400 rounded outline-none h-36px;

  &:focus {
    @apply outline-none border-gray-700;
  }
  &.enabled {
    @apply bg-white;
  }
  &.disabled {
    @apply bg-oLightestGray;
  }
}
.vbga-results {
  @apply absolute w-full bg-white border rounded-b-lg text-14px font-medium text-oDark;
  top: 60px;
  left: 0;
  z-index: 5;

  li {
    padding: 0.65rem;
    cursor: pointer;
    @apply truncate flex justify-between items-center cursor-pointer;

    &.highlighted {
      @apply bg-gray-300 font-semibold;
    }
  }
}
</style>
