<template>
  <base-layout>
    <add-edit-parking-area />
    <content-section :spacing="false">
      <div class="content-spacing">
        <!-- <div class="grid grid-cols-1 mb-10 md:grid-cols-2"> -->
        <div class="grid grid-cols-1 mb-10">
          <div class="">
            <title-plus
              :title="$t('components.parkingAreaManagement.headline')"
              @plus="add"
              :hidePlus="$acl.canNotCreate('Parking Areas')"
            />
          </div>
        </div>

        <div
          class="flex flex-col mt-4 mb-10 space-x-0 space-y-4 md:space-x-4 md:space-y-0 md:items-center md:flex-row"
        >
          <SummaryCard
            :title="
              $t('components.parkingAreaManagement.summary.totalParkingArea')
            "
            :value="indexMetaData.summary.total"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t('components.parkingAreaManagement.summary.legallyParked')
            "
            :value="indexMetaData.summary.legally_parked"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t('components.parkingAreaManagement.summary.illegallyParked')
            "
            :value="indexMetaData.summary.illegally_parked"
            variant="gray"
          />
        </div>
      </div>

      <div
        v-if="$acl.canNotView('Parking Areas')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`ParkingAreaIndex`"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        :isMultipleViewEnabled="true"
        @meta="(e) => (indexMetaData = e)"
        @view="(v) => (layoutView = v)"
      >
        <template #default="{ data }">
          <template v-if="layoutView === 'list'">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" text-fallback-always>
                  <FSTableRowItem>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewParkingArea',
                        params: { id: item.id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewServiceArea',
                        params: {
                          id: item.geofence.id ? item.geofence.id : '',
                        },
                      }"
                    >
                      {{ item.geofence.name || '--' }}
                    </router-link>
                  </FSTableRowItem>

                  <FSTableRowItem :text="item.address ? item.address : '--'" />

                  <FSTableRowItem>
                    <x-status
                      :variant="item.is_active ? 'green' : 'gray'"
                      :text="item.is_active ? 'Active' : 'Inactive'"
                      profile="fleet"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div class="flex items-center">
                      <oto-edit-icon @click="edit(item)" />
                      <MoreActionsDropdown
                        :key="`more-actions-${itemIndex}`"
                        :type="`parkingArea`"
                        :data="item"
                        @exportKML="handleExportKML(item)"
                        @change="handleAction('change', itemIndex, $event)"
                        @delete="handleAction('delete', itemIndex, $event)"
                      />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewParkingArea',
                        params: { id: item.id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewServiceArea',
                        params: {
                          id: item.geofence.id ? item.geofence.id : '',
                        },
                      }"
                    >
                      {{ item.geofence.name || '--' }}
                    </router-link>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <x-status
                      :variant="item.is_active ? 'green' : 'gray'"
                      :text="item.is_active ? 'Active' : 'Inactive'"
                      profile="fleet"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.parkingAreaManagement.table.columns.address'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.address || '--' }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.parkingAreaManagement.table.columns.actions'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <oto-edit-icon @click="edit(item)" />
                          <MoreActionsDropdown
                            :key="`more-actions-${itemIndex}`"
                            :type="`parkingArea`"
                            :data="item"
                            @exportKML="handleExportKML(item)"
                            @change="handleAction('change', itemIndex, $event)"
                            @delete="handleAction('delete', itemIndex, $event)"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
          <template v-if="layoutView === 'grid'">
            <FSTableRow
              v-for="(item, itemIndex) in data"
              :key="itemIndex"
              text-fallback-always
            >
              <FSTableGridItem>
                <div
                  class="bg-gray-50 rounded-lg flex justify-between items-center p-1"
                >
                  <div>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewParkingArea',
                        params: { id: item.id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                  <div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" />
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :type="`parkingArea`"
                      :data="item"
                      @exportKML="handleExportKML(item)"
                      @change="handleAction('change', itemIndex, $event)"
                      @delete="handleAction('delete', itemIndex, $event)"
                    />
                  </div>
                </div>
                <div class="h-52">
                  <MapView :data="item" />
                </div>
              </FSTableGridItem>
            </FSTableRow>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'

import XStatus from '@/components/badge/XStatus'

import { ParkingAreaConfig } from '@/config/ParkingAreaConfig'
import { EventBus } from '@/utils/EventBus'

import SummaryCard from '@/components/cards/SummaryCard'
import AddEditParkingArea from './AddEditParkingArea'
import MapView from '@/components/service-area/MapView'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableGridItem,
} from '@/components/fs-table'

import {
  parseToOverlay,
  createKML,
  downloadKMLFile,
} from '@/composites/geofence/shared/geofence'

export default {
  name: 'ParkingAreas',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    // DateRangePicker,
    SummaryCard,
    XStatus,
    MoreActionsDropdown: () =>
      import('@/composites/geofence/shared/MoreActionsDropdown'),
    AddEditParkingArea,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableGridItem,
    MapView,
  },
  data() {
    return {
      layoutView: 'list',
      indexMetaData: {
        summary: {
          total: 0,
          legally_parked: 0,
          illegally_parked: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: ParkingAreaConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.name'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.serviceAreaName'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.address'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.status'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.name'
          )}`,
          width: '35%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.serviceAreaName'
          )}`,
          width: '35%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.status'
          )}`,
          width: '20%',
          sort: null,
        },
      ],
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(ParkingAreaConfig.events.refresh, () => {
      this.$store.dispatch('fsTable/fetchData')
    })
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    parseToOverlay,
    createKML,
    downloadKMLFile,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    add: function() {
      console.log('parking = ')
      EventBus.$emit(ParkingAreaConfig.events.editingData, {
        // enable status-switch by default
        status_active: true,
      })
      dispatchEvent(new Event(ParkingAreaConfig.events.sorToggle))
      // console.log("asdfdsaf");
    },
    edit: function(item) {
      console.log(item)
      this.$http
        .get(ParkingAreaConfig.api.details(item.id))
        .then((res) => {
          console.log('parking = ', res.data)
          EventBus.$emit(ParkingAreaConfig.events.editingData, res.data)
          dispatchEvent(new Event(ParkingAreaConfig.events.sorToggle))
        })
        .catch((err) => {
          console.log(err)
        })
    },
    view: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return ParkingAreaConfig.api.update(id)
    },
    handleAction(type, index, data) {
      this.$store.dispatch('fsTable/fetchData')
      console.log('service-area', type, index, data)
      if (type === 'change') {
        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Status`,
          text: `Parking Area Status has been changed`,
        })

        return
      }

      if (type === 'delete') {
        const name = this.indexData[index].name || '--'

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Parking area deleted`,
          text: `${name} parking area has been deleted`,
        })

        return
      }
    },

    handleExportKML(data) {
      const coordinates = this.parseToOverlay(data.coords)

      const kmlString = this.createKML(coordinates)

      this.downloadKMLFile(data.name, kmlString)
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
